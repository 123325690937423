import React from "react";
import FormModal from "../App/FormModal";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { BiCheck } from "react-icons/bi";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="contact-area">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <StaticImage
              src="../../assets/images/sun-icon.jpg"
              alt="about"
              style={{ verticalAlign: "middle", marginRight: "5px" }}
              width={32}
              height={32}
            />
            {t("Kontakt")}
          </span>
          <h2>{t("Company - Contact - Title - 1")}</h2>
          <p>{t("Company - Contact - Text - 1")}</p>
        </div>
        <FormModal />
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6">
            <div className="contact-form">
              <form id="contactForm" onSubmit={postForm}>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <i className="fa fa-user"></i>
                      <input
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder={t("Company - Contact - Form - Name")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <i className="fa fa-envelope"></i>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder={t("Company - Contact - Form - EMail")}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <i className="fa fa-envelope"></i>
                    <input
                      type="text"
                      name="project"
                      id="project"
                      className="form-control"
                      placeholder={t("Company - Contact - Form - Liegenschaft")}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <i className="fa fa-envelope"></i>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      className="form-control"
                      placeholder={t("Company - Contact - Form - Betreff")}
                      required
                    />
                  </div>
                </div>
              </form>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder={t("Company - Contact - Form - Nachricht")}
                    form="contactForm"
                    name="body"
                    id="body"
                    rows="4"
                    cols="50"
                    wrap="soft"
                  ></textarea>
                </div>
              </div>
              <div
                className="g-recaptcha"
                data-sitekey="6Ldhoq0UAAAAAK0f87yH4YOSZ-F9JWNnJbyKp-zW"
              ></div>
              <br />
              <button className="default-btn-reactIcon" form="contactForm">
                <BiCheck size={24} />
                &nbsp; {t("Senden")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

function getFingerprint() {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var txt = "https://zammad.com";
  ctx.textBaseline = "top";
  ctx.font = "12px 'Arial'";
  ctx.textBaseline = "alphabetic";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = "#069";
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = "rgba(100, 200, 0, 0.7)";
  ctx.fillText(txt, 4, 17);
  return canvas.toDataURL();
}

function getFormConfig() {
  return new Promise(function (myResolve, myReject) {
    var data = new FormData();
    var xhr = new XMLHttpRequest();
    data.append("fingerprint", getFingerprint());
    xhr.open("POST", "https://helpdesk.blockstrom.com/api/v1/form_config");
    xhr.onload = function () {
      if (xhr.status === 200) {
        myResolve(xhr.response);
        return xhr.response;
      } else {
        console.log("error");
        myReject("Error - Post did not work");
      }
    };
    xhr.onerror = () => console.log("error");
    xhr.send(data);
  });
}

function showFormSuccess() {
  const modal = document.getElementById("myModal");
  const close = document.querySelectorAll(".close")[0];

  const openModal = function () {
    modal.style.display = "block";
  };
  const closeModal = function () {
    modal.style.display = "none";
  };
  //event listeners
  openModal();
  close.addEventListener("click", closeModal, false);

  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = "none";
    }
  };

  // Auskommentiert, da bei Fehlermeldung nicht Sinnvoll
  // setTimeout(function () {
  //   modal.style.display = "none";
  // }, 5000);
}

function postForm(e) {
  e.preventDefault();
  getFormConfig()
    .then(function (formToken) {
      const formFingerprint = getFingerprint();
      var data = new FormData();
      var xhr = new XMLHttpRequest();
      data.append(
        "title",
        "Kontakt Formular Website" +
          ": " +
          document.querySelector(`#subject`).value
      );
      data.append("name", document.querySelector(`#name`).value);
      data.append("email", document.querySelector(`#email`).value);
      data.append(
        "body",
        "Liegenschaft: " +
          (document.querySelector(`#project`).value !== ""
            ? document.querySelector(`#project`).value
            : "Keine Angabe") +
          "\n" +
          document.querySelector(`#body`).value
      );
      data.append("token", JSON.parse(formToken).token);
      data.append("fingerprint", formFingerprint);
      xhr.open("POST", "https://helpdesk.blockstrom.com/api/v1/form_submit");
      xhr.onload = function () {
        if (xhr.status === 200) {
          console.log("success");
        } else {
          document.querySelector(`#myModal-header`).innerHTML =
            "Error - Something went wrong";
          document.querySelector(`#myModal-text`).innerHTML =
            "Please contact us via email: info@blockstrom";
          console.log("error");
        }
      };
      xhr.onerror = () => console.log("error");
      xhr.send(data);
    })
    .catch(function (error) {
      document.querySelector(`#myModal-header`).innerHTML =
        "Error - Something went wrong";
      document.querySelector(`#myModal-text`).innerHTML =
        "Please contact us via email: info@blockstrom";
      showFormSuccess();
      console.log(error);
    })
    .then(function () {
      showFormSuccess();
      document.querySelector(`#contactForm`).reset();
    });
}
