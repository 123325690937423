import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div id="myModal" className="modal">
      <div className="modal-content slideDown container">
        <div className="modal-header row justify-content-center">
          <span className="close" id="closeModal">
            &times;
          </span>
          <h2 id="myModal-header">
            {t("Company - Contact - Form - Text - 1")}t
          </h2>
        </div>
        <div className="modal-body row justify-content-center">
          <div className="modal-form col-lg-12 col-md-12">
            <div className="form-row">
              <p id="myModal-text">
                {t("Company - Contact - Form - Text - 2")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
